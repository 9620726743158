import { store } from '../../../app/store';
import {
  ApiList,
  ApiObject,
  Company,
  BankInformation,
  DashboardData,
  SingleDashboardData,
  RecurrentDashboardData,
  Statement,
  Buyer,
  BillingInformation,
  ShopifyButtonDesign,
  ShippingConfiguration,
} from '../../../app/type';

import { startLoading, stopLoading, setCompany } from '../../../features/seller/sellerSlice';

import client from '../client';

interface CreateData {
  data: { name: string; default_billing_information?: Partial<BillingInformation> };
}
export const create = (data: CreateData): Promise<ApiObject<Company>> => {
  const formData = new FormData();
  Object.keys(data.data).map((key) => {
    if (key === 'image_file') {
      // @ts-expect-error: ts(7053)
      formData.append('data[image_file]', data.data[key], 'image.png');
    } else if (key === 'default_billing_information' && data.data.default_billing_information) {
      Object.keys(data.data.default_billing_information).forEach((billingKey: string) => {
        formData.append(
          `data[billing_information][${billingKey}]`,
          // @ts-expect-error: ts(7053)
          data.data.default_billing_information?.[billingKey]
        );
      });
    } else {
      // @ts-expect-error: ts(7053)
      formData.append(`data[${key}]`, data.data[key]);
    }
  });
  store.dispatch(startLoading());
  return client
    .post('/v1/companies', formData)
    .then(({ data: body }: { data: ApiObject<Company> }) => {
      store.dispatch(setCompany(body.data));
      return body;
    })
    .catch((error: any) => {
      console.error({ createError: error });
      throw error;
    })
    .finally(() => {
      store.dispatch(stopLoading());
    });
};

export const createEmptyCompany = (
  company_id: string | undefined,
  choice?: string
): Promise<ApiObject<Company>> => {
  store.dispatch(startLoading());
  return client
    .post('/v1/companies', { id: company_id, type: choice })
    .then(({ data: body }: { data: ApiObject<Company> }) => {
      store.dispatch(setCompany(body.data));
      return body;
    })
    .catch((error: any) => {
      console.error({ createError: error });
      throw error;
    })
    .finally(() => {
      store.dispatch(stopLoading());
    });
};

interface UpdateData {
  data: Partial<Company>;
}
export const update = (data: UpdateData): Promise<ApiObject<Company>> => {
  const formData = new FormData();
  Object.keys(data.data).map((key) => {
    key == 'image_file'
      ? // @ts-expect-error: ts(7053)
        formData.append('data[image_file]', data.data[key], 'image.png')
      : // @ts-expect-error: ts(7053)
        formData.append(`data[${key}]`, data.data[key]);
  });
  store.dispatch(startLoading());
  return client
    .put(`/v1/companies/${data.data.id}`, formData)
    .then(({ data: body }: { data: ApiObject<Company> }) => {
      store.dispatch(setCompany(body.data));
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    })
    .finally(() => {
      store.dispatch(stopLoading());
    });
};

export const list = (): Promise<ApiList<Company>> => {
  store.dispatch(startLoading());
  return client({
    method: 'get',
    url: `/v1/companies`,
  })
    .then(({ data: body }: { data: ApiList<Company> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ listError: error });
      throw error;
    })
    .finally(() => {
      store.dispatch(stopLoading());
    });
};

export const show = (companyId: string): Promise<ApiObject<Company>> => {
  store.dispatch(startLoading());
  return client({
    method: 'get',
    url: `/v1/companies/${companyId}`,
  })
    .then(({ data: body }: { data: ApiObject<Company> }) => {
      store.dispatch(setCompany(body.data));
      return body;
    })
    .catch((error: any) => {
      console.error({ showError: error });
      throw error;
    })
    .finally(() => {
      store.dispatch(stopLoading());
    });
};

export const bankInformations = (companyId: string): Promise<ApiList<BankInformation>> => {
  store.dispatch(startLoading());
  return client({
    method: 'get',
    url: `/v1/companies/${companyId}/bank_informations`,
  })
    .then(({ data: body }: { data: ApiList<BankInformation> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ bankInformationError: error });
      throw error;
    })
    .finally(() => {
      store.dispatch(stopLoading());
    });
};

export const dashboard = (
  companyId: string,
  filterParams: { [key: string]: any }
): Promise<ApiObject<DashboardData>> => {
  return client({
    method: 'get',
    url: `/v1/companies/${companyId}/dashboard`,
    params: { filter_params: filterParams },
  })
    .then(({ data: body }: { data: ApiObject<DashboardData> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ listDataError: error });
      throw error;
    });
};

export const last_transactions = (companyId: string): Promise<ApiList<Statement>> => {
  return client({
    method: 'get',
    url: `/v1/companies/${companyId}/last_transactions`,
  })
    .then(({ data: body }: { data: ApiList<Statement> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ listDataError: error });
      throw error;
    });
};

export const last_debtors = (companyId: string): Promise<ApiList<Buyer>> => {
  return client({
    method: 'get',
    url: `/v1/companies/${companyId}/last_debtors`,
  })
    .then(({ data: body }: { data: ApiList<Buyer> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ listDataError: error });
      throw error;
    });
};

export const singleDashboard = (companyId: string): Promise<ApiObject<SingleDashboardData>> => {
  return client({
    method: 'get',
    url: `/v1/companies/${companyId}/single_dashboard`,
  })
    .then(({ data: body }: { data: ApiObject<SingleDashboardData> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ listDataError: error });
      throw error;
    });
};

export const recurrentDashboard = (
  companyId: string
): Promise<ApiObject<RecurrentDashboardData>> => {
  return client({
    method: 'get',
    url: `/v1/companies/${companyId}/recurrent_dashboard`,
  })
    .then(({ data: body }: { data: ApiObject<RecurrentDashboardData> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ listDataError: error });
      throw error;
    });
};

export const turnOffPopup = (companyId: string): Promise<{ message: string }> => {
  return client({
    method: 'post',
    url: `/v1/companies/${companyId}/turn_off_popup`,
  })
    .then(({ data: body }: { data: { message: string } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ listDataError: error });
      throw error;
    });
};

export const filters = (companyId: string): Promise<ApiObject<{ [key: string]: any }>> => {
  return client({
    method: 'get',
    url: `/v1/companies/${companyId}/filters`,
  })
    .then(({ data: body }: { data: ApiObject<{ [key: string]: any }> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ listDataError: error });
      throw error;
    });
};

export const sendClientPortalEmail = (
  companyId: string,
  buyerId: string
): Promise<{ message: string }> => {
  return client({
    method: 'post',
    url: `/v1/companies/${companyId}/send_client_portal_email`,
    params: { buyer_id: buyerId },
  })
    .then(({ data: body }: { data: { message: string } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export const shopifyButton = (companyId: string): Promise<ApiObject<ShopifyButtonDesign>> => {
  return client({
    method: 'get',
    url: `/v1/companies/${companyId}/shopify_button`,
  })
    .then(({ data: body }: { data: ApiObject<ShopifyButtonDesign> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ listDataError: error });
      throw error;
    });
};

interface ShopifyButtonData {
  data: ShopifyButtonDesign;
}

export const updateShopifyButton = (data: ShopifyButtonData): Promise<ShopifyButtonDesign> => {
  return client({
    method: 'post',
    url: `/v1/companies/${data.data.company_id}/update_shopify_button`,
    data,
  })
    .then(({ data: body }: { data: ShopifyButtonDesign }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ listDataError: error });
      throw error;
    });
};

export const shippingConfiguration = (companyId: string): Promise<ShippingConfiguration> => {
  return client({
    method: 'get',
    url: `/v1/companies/${companyId}/shipping_configuration`,
  })
    .then(({ data: body }: { data: ShippingConfiguration }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ listDataError: error });
      throw error;
    });
};

interface ShippingConfigurationData {
  data: ShippingConfiguration;
}

export const updateShippingConfiguration = (
  companyId: string,
  data: ShippingConfigurationData
): Promise<ShippingConfiguration> => {
  return client({
    method: 'put',
    url: `/v1/companies/${companyId}/update_shipping_configuration`,
    data,
  })
    .then(({ data: body }: { data: ShippingConfiguration }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ listDataError: error });
      throw error;
    });
};

export default {
  create,
  update,
  list,
  show,
  bankInformations,
  dashboard,
  singleDashboard,
  recurrentDashboard,
  createEmptyCompany,
  turnOffPopup,
  filters,
  shopifyButton,
  updateShopifyButton,
  shippingConfiguration,
  updateShippingConfiguration,
};
