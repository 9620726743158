import {
  ApiObject,
  SinglePayment,
  ApiList,
  SinglePaymentBuyer,
  ExtraField,
} from '../../../app/type';

import client from '../client';

export const show = (singlePaymentId: string): Promise<ApiObject<SinglePayment>> => {
  return client({
    method: 'get',
    url: `/v1/single_payments/${singlePaymentId}`,
  })
    .then(({ data: body }: { data: ApiObject<SinglePayment> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ listError: error });
      throw error;
    });
};

interface CreatesSinglePayment {
  single_payment: Partial<Omit<SinglePayment, 'products'>>;
  products: { id: string; quantity: number; price: number }[];
  product: { name?: string; value?: number; currency?: string };
}

const processValue = (value: any) => {
  return value === null || value === undefined ? '' : value;
};

export const update = (data: CreatesSinglePayment): Promise<ApiObject<SinglePayment>> => {
  const formData = new FormData();
  Object.keys(data.single_payment).map((key) => {
    if (key == 'image_file') {
      // @ts-expect-error: ts(7053)
      formData.append('single_payment[image_file]', data.single_payment[key], 'image.png');
    } else if (
      [
        'extra_fields',
        'mandatory_extra_fields',
        'default_extra_fields',
        'extra_fields_options',
        'extra_fields_kind',
        'shipping_config',
      ].includes(key)
    ) {
      // @ts-expect-error: ts(7053)
      formData.append(`single_payment[${key}]`, JSON.stringify(data.single_payment[key]));
    } else {
      // @ts-expect-error: ts(7053)
      formData.append(`single_payment[${key}]`, processValue(data.single_payment[key]));
    }
  });
  Object.keys(data.product).map((key) => {
    // @ts-expect-error: ts(7053)
    formData.append(`product[${key}]`, data.product[key]);
  });
  formData.append(`products`, JSON.stringify(data.products));
  return client
    .put(`/v1/single_payments/${data.single_payment.id}`, formData)
    .then(({ data: body }: { data: ApiObject<SinglePayment> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export const create = (data: CreatesSinglePayment): Promise<ApiObject<SinglePayment>> => {
  const formData = new FormData();
  Object.keys(data.single_payment).map((key) => {
    if (key == 'image_file') {
      // @ts-expect-error: ts(7053)
      formData.append('single_payment[image_file]', data.single_payment[key], 'image.png');
    } else if (
      [
        'extra_fields',
        'mandatory_extra_fields',
        'default_extra_fields',
        'extra_fields_options',
        'extra_fields_kind',
        'shipping_config',
      ].includes(key)
    ) {
      // @ts-expect-error: ts(7053)
      formData.append(`single_payment[${key}]`, JSON.stringify(data.single_payment[key]));
    } else {
      // @ts-expect-error: ts(7053)
      formData.append(`single_payment[${key}]`, processValue(data.single_payment[key]));
    }
  });
  Object.keys(data.product).map((key) => {
    // @ts-expect-error: ts(7053)
    formData.append(`product[${key}]`, data.product[key]);
  });
  formData.append(`products`, JSON.stringify(data.products));
  return client
    .post('/v1/single_payments', formData)
    .then(({ data: body }: { data: ApiObject<SinglePayment> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ createError: error });
      throw error;
    });
};

export const destroySinglePayment = (
  singlePaymentId?: string
): Promise<ApiObject<SinglePayment>> => {
  return client({
    method: 'delete',
    url: `/v1/single_payments/${singlePaymentId}`,
  })
    .then(({ data: body }: { data: ApiObject<SinglePayment> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export const singlePaymentBuyers = (
  singlePaymentId: string,
  query?: string,
  page?: number,
  filterParams?: { [key: string]: any }
): Promise<ApiList<SinglePaymentBuyer>> => {
  return client({
    method: 'get',
    url: `/v1/single_payments/${singlePaymentId}/single_payment_buyers`,
    params: { query, page: page || 1, filter_params: filterParams },
  })
    .then(({ data: body }: { data: ApiList<SinglePaymentBuyer> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export const list = (
  companyId: string,
  query?: string,
  page?: number,
  filterParams?: { [key: string]: any }
): Promise<ApiList<SinglePayment>> => {
  return client({
    method: 'get',
    url: `/v1/single_payments`,
    params: { company_id: companyId, query, page: page || 1, filter_params: filterParams },
  })
    .then(({ data: body }: { data: ApiList<SinglePayment> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export const change_status = (
  singlePaymentId: string,
  singlePaymentStatus: string
): Promise<ApiObject<SinglePayment>> => {
  return client({
    method: 'put',
    url: `/v1/single_payments/${singlePaymentId}/change_status`,
    params: { status: singlePaymentStatus },
  })
    .then(({ data: body }: { data: ApiObject<SinglePayment> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ changeStatusError: error });
      throw error;
    });
};

export const generateSinglePaymentBuyersExcel = (
  parent?: string,
  parentId?: string,
  query?: string,
  filterParams?: { [key: string]: any }
): Promise<{ message: string }> => {
  return client({
    method: 'post',
    url: `/v1/single_payments/${parentId}/generate_single_payment_buyers_excel`,
    params: { parent: parent, query: query, filter_params: filterParams },
  })
    .then(({ data: body }: { data: { message: string } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ changeStatusError: error });
      throw error;
    });
};

export const generateExcel = (
  parent?: string,
  parentId?: string,
  query?: string,
  filterParams?: { [key: string]: any }
): Promise<{ message: string }> => {
  return client({
    method: 'post',
    url: `/v1/single_payments/generate_excel`,
    params: { parent: parent, parent_id: parentId, query: query, filter_params: filterParams },
  })
    .then(({ data: body }: { data: { message: string } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ changeStatusError: error });
      throw error;
    });
};

export const subscribe = (
  productId: string,
  buyerId: string,
  extra_fields?: { [key: string]: string }
): Promise<ApiObject<SinglePaymentBuyer>> => {
  return client({
    method: 'post',
    url: `/v1/single_payments/${productId}/subscribe`,
    data: { buyer_id: buyerId, extra_fields: extra_fields },
  })
    .then(({ data: body }: { data: ApiObject<SinglePaymentBuyer> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ subscriptionError: error });
      throw error;
    });
};

export const singlePaymentBuyer = (
  subscriptionId: string,
  buyerId: string,
  extra_fields?: { [key: string]: string }
): Promise<ApiObject<SinglePaymentBuyer>> => {
  return client({
    method: 'post',
    url: `/v1/single_payments/${subscriptionId}/single_payment_buyer`,
    data: { buyer_id: buyerId, extra_fields: extra_fields },
  })
    .then(({ data: body }: { data: ApiObject<SinglePaymentBuyer> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ subscriptionError: error });
      throw error;
    });
};

export const questions = (singlePaymentId: string): Promise<ApiList<ExtraField>> => {
  return client({
    method: 'get',
    url: `v1/single_payments/${singlePaymentId}/questions`,
  })
    .then(({ data: body }: { data: ApiList<ExtraField> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ questionsError: error });
      throw error;
    });
};
