import {
  ApiObject,
  Subscription,
  ApiList,
  SubscriptionBuyer,
  ExtraField,
  ProductHistory,
} from '../../../app/type';

import client from '../client';

interface CreateSubscription {
  subscription: Partial<Omit<Subscription, 'products'>>;
  products: { id: string; quantity: number; price: number }[];
  product: { name?: string; value?: number; currency?: string };
}

const processValue = (value: any) => {
  return value === null || value === undefined ? '' : value;
};

export const update = (data: CreateSubscription): Promise<ApiObject<Subscription>> => {
  const formData = new FormData();
  Object.keys(data.subscription).map((key) => {
    if (key == 'image_file') {
      // @ts-expect-error: ts(7053)
      formData.append('subscription[image_file]', data.subscription[key], 'image.png');
    } else if (
      [
        'extra_fields',
        'mandatory_extra_fields',
        'default_extra_fields',
        'extra_fields_options',
        'extra_fields_kind',
        'shipping_config',
      ].includes(key)
    ) {
      // @ts-expect-error: ts(7053)
      formData.append(`subscription[${key}]`, JSON.stringify(data.subscription[key]));
    } else {
      // @ts-expect-error: ts(7053)
      formData.append(`subscription[${key}]`, processValue(data.subscription[key]));
    }
  });
  Object.keys(data.product).map((key) => {
    // @ts-expect-error: ts(7053)
    formData.append(`product[${key}]`, data.product[key]);
  });
  formData.append(`products`, JSON.stringify(data.products));
  return client
    .put(`/v1/subscriptions/${data.subscription.id}`, formData)
    .then(({ data: body }: { data: ApiObject<Subscription> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export const create = (data: CreateSubscription): Promise<ApiObject<Subscription>> => {
  const formData = new FormData();
  Object.keys(data.subscription).map((key) => {
    if (key == 'image_file') {
      // @ts-expect-error: ts(7053)
      formData.append('subscription[image_file]', data.subscription[key], 'image.png');
    } else if (
      [
        'extra_fields',
        'mandatory_extra_fields',
        'default_extra_fields',
        'extra_fields_options',
        'extra_fields_kind',
        'shipping_config',
      ].includes(key)
    ) {
      // @ts-expect-error: ts(7053)
      formData.append(`subscription[${key}]`, JSON.stringify(data.subscription[key]));
    } else {
      // @ts-expect-error: ts(7053)
      formData.append(`subscription[${key}]`, processValue(data.subscription[key]));
    }
  });
  Object.keys(data.product).map((key) => {
    // @ts-expect-error: ts(7053)
    formData.append(`product[${key}]`, data.product[key]);
  });
  formData.append(`products`, JSON.stringify(data.products));
  return client
    .post('/v1/subscriptions', formData)
    .then(({ data: body }: { data: ApiObject<Subscription> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ createError: error });
      throw error;
    });
};

export const destroy = (subscriptionId: string): Promise<ApiObject<Subscription>> => {
  return client({
    method: 'delete',
    url: `/v1/subscriptions/${subscriptionId}`,
  })
    .then(({ data: body }: { data: ApiObject<Subscription> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export const subscriptionBuyers = (
  subscriptionId: string,
  query?: string,
  page?: number,
  filterParams?: { [key: string]: string | Date | number | null | undefined }
): Promise<ApiList<SubscriptionBuyer>> => {
  return client({
    method: 'get',
    url: `/v1/subscriptions/${subscriptionId}/subscription_buyers`,
    params: { query, page: page || 1, filter_params: filterParams },
  })
    .then(({ data: body }: { data: ApiList<SubscriptionBuyer> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export const list = (
  companyId: string,
  query?: string,
  page?: number,
  filterParams?: { [key: string]: any }
): Promise<ApiList<Subscription>> => {
  return client({
    method: 'get',
    url: `/v1/subscriptions`,
    params: { company_id: companyId, query, page: page || 1, filter_params: filterParams },
  })
    .then(({ data: body }: { data: ApiList<Subscription> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export const show = (subscriptionId: string): Promise<ApiObject<Subscription>> => {
  return client({
    method: 'get',
    url: `/v1/subscriptions/${subscriptionId}`,
  })
    .then(({ data: body }: { data: ApiObject<Subscription> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
};

export const change_status = (
  subscriptionId: string,
  subscriptionStatus: string
): Promise<ApiObject<Subscription>> => {
  return client({
    method: 'put',
    url: `/v1/subscriptions/${subscriptionId}/change_status`,
    params: { status: subscriptionStatus },
  })
    .then(({ data: body }: { data: ApiObject<Subscription> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ changeStatusError: error });
      throw error;
    });
};

export const generateSubscriptionBuyersExcel = (
  parent?: string,
  parentId?: string,
  query?: string,
  filterParams?: { [key: string]: any }
): Promise<{ message: string }> => {
  return client({
    method: 'post',
    url: `/v1/subscriptions/${parentId}/generate_subscription_buyers_excel`,
    params: { parent: parent, query: query, filter_params: filterParams },
  })
    .then(({ data: body }: { data: { message: string } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ changeStatusError: error });
      throw error;
    });
};

export const generateExcel = (
  parent?: string,
  parentId?: string,
  query?: string,
  filterParams?: { [key: string]: any }
): Promise<{ message: string }> => {
  return client({
    method: 'post',
    url: `/v1/subscriptions/generate_excel`,
    params: { parent: parent, parent_id: parentId, query: query, filter_params: filterParams },
  })
    .then(({ data: body }: { data: { message: string } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ changeStatusError: error });
      throw error;
    });
};

export const subscriptionBuyer = (
  subscriptionId: string,
  buyerId: string,
  extra_fields?: { [key: string]: string }
): Promise<ApiObject<SubscriptionBuyer>> => {
  return client({
    method: 'post',
    url: `/v1/subscriptions/${subscriptionId}/subscription_buyer`,
    data: { buyer_id: buyerId, extra_fields: extra_fields },
  })
    .then(({ data: body }: { data: ApiObject<SubscriptionBuyer> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ subscriptionError: error });
      throw error;
    });
};

export const questions = (subscriptionId: string): Promise<ApiList<ExtraField>> => {
  return client({
    method: 'get',
    url: `v1/subscriptions/${subscriptionId}/questions`,
  })
    .then(({ data: body }: { data: ApiList<ExtraField> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ questionsError: error });
      throw error;
    });
};

export const nextSalesReport = (): Promise<{ message: string }> =>
  client({ method: 'get', url: `/v1/subscriptions/next_sales_report` })
    .then(({ data: body }: { data: { message: string } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });

export const productsHistory = (subscriptionId: string): Promise<ApiList<ProductHistory>> => {
  return client({
    method: 'get',
    url: `v1/subscriptions/${subscriptionId}/products_history`,
  })
    .then(({ data: body }: { data: ApiList<ProductHistory> }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ questionsError: error });
      throw error;
    });
};

export const subscriptionBuyersReport = (): Promise<{ message: string }> =>
  client({ method: 'get', url: `/v1/subscriptions/subscription_buyers_report` })
    .then(({ data: body }: { data: { message: string } }) => {
      return body;
    })
    .catch((error: any) => {
      console.error({ updateError: error });
      throw error;
    });
