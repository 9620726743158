import React, { Fragment, useEffect, useMemo, useState } from 'react';

import { FormikProps } from 'formik';
import { Buyer, Discount, Payable, ShippingInformation, Suggestion } from '../../../../app/type';

import {
  TextField,
  Typography,
  Grid,
  MenuItem,
  useMediaQuery,
  Alert,
  Button,
  Divider,
  CircularProgress,
} from '@mui/material';

import { COMMUNES_BY_REGION } from '../../../../common/constants/communes';
import { REGIONS } from '../../../../common/constants/regions';
import styles from './Forms.module.scss';

import DeliveryAndRecurrence from '../DeliveryAndRecurrence/DeliveryAndRecurrence';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { downcase, formatCurrency, formatRUT } from '../../../../common/utils';

interface FormProps {
  formik: FormikProps<Partial<BuyerInfo>>;
  shippingFormik: FormikProps<ShippingInformation>;
  payable?: Payable;
  customRecurrenceProps?: {
    selectedRecurrence: string | null;
    setSelectedRecurrence: (arg: string | null) => void;
    amount: number | null;
    setAmount: (arg: number | null) => void;
    errors: { [key: string]: string | null };
  };
  shippingCost: number | null;
  abortSelectedSuggestion?: (e: any) => void;
  suggestionState?: {
    state: Suggestion | null | undefined;
    setState: (s: Suggestion | null | undefined) => void;
  };
  loadingShippingCost?: boolean;
  validateDiscountCode?: (event: any) => void;
  loadingDiscount?: boolean;
  extraDiscount?: Partial<Discount>;
  validExtraDiscount?: boolean;
  allowCustomCheckout?: boolean;
  productsToSubscribe?: string[];
  codeDiscountState?: {
    state: string | undefined;
    setState: (a: string) => void;
  };
}

interface BuyerInfo extends Omit<Buyer, 'id'> {
  id?: string;
}

const FormSelector = (props: FormProps): React.ReactElement => {
  const isTablet = useMediaQuery(`(max-width: 1330px)`);

  return (
    <>
      {!props.allowCustomCheckout ? (
        <DefaultForm
          formik={props.formik}
          payable={props.payable}
          shippingCost={props.shippingCost}
          suggestionState={props.suggestionState}
          abortSelectedSuggestion={props.abortSelectedSuggestion}
          shippingFormik={props.shippingFormik}
        />
      ) : null}
      {props.allowCustomCheckout ? (
        <>
          <FormWithDelivery
            formik={props.formik}
            payable={props.payable}
            shippingFormik={props.shippingFormik}
            shippingCost={props.shippingCost}
            validateDiscountCode={props.validateDiscountCode}
            loadingDiscount={props.loadingDiscount}
            extraDiscount={props.extraDiscount}
            validExtraDiscount={props.validExtraDiscount}
            allowCustomCheckout={props.allowCustomCheckout}
            codeDiscountState={props.codeDiscountState}
          />
          {isTablet ? (
            <DeliveryAndRecurrence
              payable={props.payable}
              customRecurrenceProps={props.customRecurrenceProps}
              shippingCost={props.shippingCost}
              loadingShippingCost={props.loadingShippingCost || false}
              productsToSubscribe={props.productsToSubscribe}
            />
          ) : null}
        </>
      ) : null}
    </>
  );
};

const DefaultForm = (props: FormProps): React.ReactElement => {
  const [companyColor, setCompanyColor] = useState<string>('#4653E3');
  const isMobile = useMediaQuery(`(max-width: 900px)`);

  const columnsWidth = useMemo(() => {
    const ef = props.payable?.extra_fields || {};
    if (Object.entries(ef).filter((f) => f[1]).length > 4) return 6;
    return 12;
  }, [props.payable?.extra_fields]);

  const applyDynamicBorder = (element: HTMLElement) => {
    element.style.setProperty('--dynamic-border-color', companyColor);
  };

  const removeDynamicBorder = (element: HTMLElement) => {
    element.style.setProperty('--dynamic-border-color', companyColor);
  };

  const style = props.payable?.company?.payment_design
    ? {
        color: props.payable?.company.payment_design.background_color,
        borderColor: props.payable?.company.payment_design.background_color,
      }
    : {};

  const handleExtraFieldChange = (event: any, id: string, question: string) => {
    if (downcase(question) === 'rut') {
      props.formik.setFieldValue('extra_fields', {
        ...props.formik.values.extra_fields,
        [id]: formatRUT(event.target.value),
      });
    } else {
      props.formik.setFieldValue('extra_fields', {
        ...props.formik.values.extra_fields,
        [id]: event.target.value,
      });
    }
  };

  useEffect(() => {
    if (props.payable) {
      setCompanyColor(
        props.payable?.company.payment_design
          ? props.payable?.company.payment_design.background_color
          : '#4653E3'
      );
    }
  }, [props.payable]);

  return (
    <div
      className={styles.buyerData}
      style={{ width: props.allowCustomCheckout ? '100%' : '360px' }}
    >
      <form onSubmit={props.formik.handleSubmit} key={0}>
        {!props.allowCustomCheckout && (
          <div className={styles.titleForm}>
            <Typography
              className={styles.title}
              id="clientForm"
              style={{
                color: props.payable?.company?.payment_design?.background_color || '#4653E3',
                marginTop: isMobile && !props.allowCustomCheckout ? '30px' : '0',
              }}
            >
              Resumen de pago
            </Typography>
            <Typography className={styles.stepDescription}>
              <span className={styles.mediumText}>¡Completa este formulario!</span> y luego podrás
              visualizar el resumen de tu pago.
            </Typography>
          </div>
        )}
        {props.suggestionState?.state !== undefined ? (
          <Alert
            variant="outlined"
            severity="info"
            style={style}
            icon={
              <FontAwesomeIcon
                icon={faInfoCircle}
                color={props.payable?.company?.payment_design?.background_color}
              />
            }
            className={styles.infoAlert}
            action={
              <Button
                size="small"
                sx={{
                  backgroundColor: props.payable?.company?.payment_design?.background_color,
                  color: 'white',
                }}
                onClick={props.abortSelectedSuggestion}
              >
                {props.suggestionState.state === null ? 'Ok' : 'Cancelar'}
              </Button>
            }
          >
            {props.suggestionState.state === null
              ? 'Estás creando una suscripción nueva'
              : `Pagarás la membresía de ${
                  props.suggestionState.state.buyer_name
                }. La cuota a pagar es de ${formatCurrency(
                  props.suggestionState.state.current_statement_details?.amount
                )} con fecha de vencimiento ${
                  props.suggestionState.state.current_statement_details?.due_date
                }.`}
          </Alert>
        ) : null}
        <Grid container spacing={2}>
          <Grid item xs={12} className={styles.inputContainer}>
            <Typography>
              {props.allowCustomCheckout ? 'Nombre' : 'Nombre completo'}
              <span className={styles.mandatoryText}>*</span>
            </Typography>
            <TextField
              fullWidth
              required
              id="name"
              type="text"
              autoComplete="name"
              variant="outlined"
              size="small"
              value={props.formik.values.name}
              onChange={props.formik.handleChange}
              error={props.formik.touched.name && Boolean(props.formik.errors?.name)}
              helperText={props.formik.touched.name && props.formik.errors?.name}
              onMouseEnter={(e) => applyDynamicBorder(e.currentTarget)}
              onMouseLeave={(e) => removeDynamicBorder(e.currentTarget)}
              onFocus={(e) => applyDynamicBorder(e.currentTarget)}
              onBlur={(e) => removeDynamicBorder(e.currentTarget)}
            />
          </Grid>
          {props.allowCustomCheckout && (
            <Grid item xs={12} className={styles.inputContainer}>
              <Typography>
                Apellido
                <span className={styles.mandatoryText}>*</span>
              </Typography>
              <TextField
                fullWidth
                required
                id="last_name"
                type="text"
                autoComplete="last_name"
                variant="outlined"
                size="small"
                value={props.formik.values.last_name}
                onChange={props.formik.handleChange}
                error={props.formik.touched.last_name && Boolean(props.formik.errors?.last_name)}
                helperText={props.formik.touched.last_name && props.formik.errors?.last_name}
                onMouseEnter={(e) => applyDynamicBorder(e.currentTarget)}
                onMouseLeave={(e) => removeDynamicBorder(e.currentTarget)}
                onFocus={(e) => applyDynamicBorder(e.currentTarget)}
                onBlur={(e) => removeDynamicBorder(e.currentTarget)}
              />
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <Typography>
              Email<span className={styles.mandatoryText}>*</span>
            </Typography>
            <TextField
              fullWidth
              required
              id="email"
              type="text"
              autoComplete="email"
              variant="outlined"
              size="small"
              value={props.formik.values.email}
              onChange={props.formik.handleChange}
              error={props.formik.touched.email && Boolean(props.formik.errors?.email)}
              helperText={props.formik.touched.email && props.formik.errors?.email}
              onBlur={(e) => removeDynamicBorder(e.currentTarget)}
              onMouseEnter={(e) => applyDynamicBorder(e.currentTarget)}
              onMouseLeave={(e) => removeDynamicBorder(e.currentTarget)}
              onFocus={(e) => applyDynamicBorder(e.currentTarget)}
            />
          </Grid>
          {props.payable?.ask_shipping_address && (
            <Fragment>
              <Grid item xs={12} className={styles.inputContainer}>
                <Typography>
                  Región <span className={styles.mandatoryText}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  required
                  disabled={props.payable?.payment_link_configuration?.shipping_one_region}
                  select
                  id="region"
                  type="text"
                  autoComplete="region"
                  variant="outlined"
                  size="small"
                  value={props.shippingFormik.values.region}
                  onChange={(event) =>
                    props.shippingFormik.setFieldValue('region', event.target.value)
                  }
                  error={
                    props.shippingFormik.touched.region &&
                    Boolean(props.shippingFormik.errors?.region)
                  }
                  helperText={
                    props.shippingFormik.touched.region && props.shippingFormik.errors?.region
                  }
                  onMouseEnter={(e) => applyDynamicBorder(e.currentTarget)}
                  onMouseLeave={(e) => removeDynamicBorder(e.currentTarget)}
                  onFocus={(e) => applyDynamicBorder(e.currentTarget)}
                  onBlur={(e) => removeDynamicBorder(e.currentTarget)}
                >
                  {props.payable?.payment_link_configuration?.shipping_one_region ? (
                    <MenuItem value={props.payable?.payment_link_configuration.shipping_regions[0]}>
                      {props.payable?.payment_link_configuration.shipping_regions[0]}
                    </MenuItem>
                  ) : props.payable?.payment_link_configuration?.shipping_regions ? (
                    props.payable?.payment_link_configuration?.shipping_regions.map((region) => (
                      <MenuItem key={region} value={region}>
                        {region}
                      </MenuItem>
                    ))
                  ) : (
                    REGIONS.map((region) => (
                      <MenuItem key={region[0]} value={region[1]}>
                        {region[1]}
                      </MenuItem>
                    ))
                  )}
                </TextField>
              </Grid>
              <Grid item xs={12} className={styles.inputContainer}>
                <Typography>
                  Comuna <span className={styles.mandatoryText}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  required
                  select
                  id="commune"
                  type="text"
                  autoComplete="commune"
                  variant="outlined"
                  size="small"
                  value={props.shippingFormik.values.commune}
                  onChange={(event) =>
                    props.shippingFormik.setFieldValue('commune', event.target.value)
                  }
                  error={
                    props.shippingFormik.touched.commune &&
                    Boolean(props.shippingFormik.errors?.commune)
                  }
                  helperText={
                    props.shippingFormik.touched.commune && props.shippingFormik.errors?.commune
                  }
                  onMouseEnter={(e) => applyDynamicBorder(e.currentTarget)}
                  onMouseLeave={(e) => removeDynamicBorder(e.currentTarget)}
                  onFocus={(e) => applyDynamicBorder(e.currentTarget)}
                  onBlur={(e) => removeDynamicBorder(e.currentTarget)}
                >
                  {props.shippingFormik.values.region ? (
                    props.payable?.payment_link_configuration?.shipping_communes_by_region ? (
                      (
                        props.payable?.payment_link_configuration?.shipping_communes_by_region[
                          props.shippingFormik.values.region
                        ] || COMMUNES_BY_REGION[props.shippingFormik.values.region]
                      )
                        ?.sort()
                        .map((commune) => (
                          <MenuItem key={commune} value={commune}>
                            {commune}
                          </MenuItem>
                        ))
                    ) : (
                      COMMUNES_BY_REGION[props.shippingFormik.values.region]
                        ?.sort()
                        .map((commune) => (
                          <MenuItem key={commune} value={commune}>
                            {commune}
                          </MenuItem>
                        ))
                    )
                  ) : (
                    <MenuItem>Selecciona región</MenuItem>
                  )}
                </TextField>
              </Grid>
              <Grid item xs={12} md={12} marginTop={2}>
                <Typography>
                  Dirección <span className={styles.mandatoryText}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  required
                  id="address1"
                  type="text"
                  autoComplete="address1"
                  variant="outlined"
                  size="small"
                  value={props.shippingFormik.values.address1}
                  onChange={props.shippingFormik.handleChange}
                  error={
                    props.shippingFormik.touched.address1 &&
                    Boolean(props.shippingFormik.errors?.address1)
                  }
                  helperText={
                    props.shippingFormik.touched.address1 && props.shippingFormik.errors?.address1
                  }
                  onMouseEnter={(e) => applyDynamicBorder(e.currentTarget)}
                  onMouseLeave={(e) => removeDynamicBorder(e.currentTarget)}
                  onFocus={(e) => applyDynamicBorder(e.currentTarget)}
                  onBlur={(e) => removeDynamicBorder(e.currentTarget)}
                />
              </Grid>
            </Fragment>
          )}

          {Object.entries(props.payable?.extra_fields || {}).map(
            ([id, question]: [string, string]) => {
              return (
                <Grid key={id} item xs={12} md={columnsWidth}>
                  <Typography>
                    {question}
                    {props.payable?.mandatory_extra_fields?.[id] && (
                      <span className={styles.mandatoryText}>*</span>
                    )}
                  </Typography>
                  {props.payable?.extra_fields_info?.[id]['kind'] === 'string' ? (
                    <TextField
                      fullWidth
                      required={props.payable?.mandatory_extra_fields?.[id]}
                      id={question}
                      type="text"
                      autoComplete="extra_fields[id]"
                      variant="outlined"
                      size="small"
                      value={props.formik.values.extra_fields?.[id]}
                      onChange={(event: any) => handleExtraFieldChange(event, id, question)}
                      InputLabelProps={{ shrink: !!props.formik.values.extra_fields?.[id] }}
                      error={Boolean(props.formik.errors?.extra_fields?.[id as any])}
                      helperText={props.formik.errors?.extra_fields?.[id as any]}
                      onMouseEnter={(e) => applyDynamicBorder(e.currentTarget)}
                      onMouseLeave={(e) => removeDynamicBorder(e.currentTarget)}
                      onFocus={(e) => applyDynamicBorder(e.currentTarget)}
                      onBlur={(e) => removeDynamicBorder(e.currentTarget)}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      required={props.payable?.mandatory_extra_fields?.[id]}
                      id={question}
                      select
                      variant="outlined"
                      size="small"
                      value={`${props.formik.values.extra_fields?.[id]}`}
                      onChange={(event: any) => handleExtraFieldChange(event, id, question)}
                      InputLabelProps={{ shrink: !!props.formik.values.extra_fields?.[id] }}
                      error={Boolean(props.formik.errors?.extra_fields?.[id as any])}
                      helperText={props.formik.errors?.extra_fields?.[id as any]}
                      onMouseEnter={(e) => applyDynamicBorder(e.currentTarget)}
                      onMouseLeave={(e) => removeDynamicBorder(e.currentTarget)}
                      onFocus={(e) => applyDynamicBorder(e.currentTarget)}
                      onBlur={(e) => removeDynamicBorder(e.currentTarget)}
                    >
                      {props.payable?.extra_fields_info?.[id]['options']
                        .split(',')
                        .map((option: string) => (
                          <MenuItem key={`${option}`} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                </Grid>
              );
            }
          )}
        </Grid>
      </form>
    </div>
  );
};

const FormWithDelivery = (props: FormProps) => {
  const isTablet = useMediaQuery(`(max-width: 1330px)`);
  const [companyColor, setCompanyColor] = useState<string>('#4653E3');

  const applyDynamicBorder = (element: HTMLElement) => {
    element.style.setProperty('--dynamic-border-color', companyColor);
  };

  const removeDynamicBorder = (element: HTMLElement) => {
    element.style.setProperty('--dynamic-border-color', companyColor);
  };

  let queryTimeout: ReturnType<typeof setTimeout>;

  const handleDiscountCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.codeDiscountState?.setState(event.target.value);
    if (queryTimeout) clearTimeout(queryTimeout);

    queryTimeout = setTimeout(() => {
      props.validateDiscountCode && props.validateDiscountCode(event);
    }, 1000);
  };

  useEffect(() => {
    if (props.payable) {
      setCompanyColor(
        props.payable?.company.payment_design
          ? props.payable?.company.payment_design.background_color
          : '#4653E3'
      );
    }
  }, [props.payable]);

  return (
    <div>
      <div className={styles.titleForm}>
        <Typography
          className={styles.stepDescription}
          style={{
            textAlign: isTablet && props.allowCustomCheckout ? 'start' : 'center',
          }}
        >
          <span className={styles.mediumText}>¡Completa este formulario!</span> y luego podrás
          seleccionar tu medio de pago.
        </Typography>
      </div>
      <DefaultForm {...props} />
      <div
        className={styles.buyerData}
        style={{ width: props.allowCustomCheckout ? '100%' : '360px' }}
      >
        <form onSubmit={props.shippingFormik.handleSubmit} key={0}>
          <Grid container spacing={2} className={styles.inputContainer}>
            <Grid item xs={12} md={12} marginTop={2}>
              <Typography>
                Dirección <span className={styles.mandatoryText}>*</span>
              </Typography>
              <TextField
                fullWidth
                required
                id="address1"
                type="text"
                autoComplete="address1"
                variant="outlined"
                size="small"
                value={props.shippingFormik.values.address1}
                onChange={props.shippingFormik.handleChange}
                error={
                  props.shippingFormik.touched.address1 &&
                  Boolean(props.shippingFormik.errors?.address1)
                }
                helperText={
                  props.shippingFormik.touched.address1 && props.shippingFormik.errors?.address1
                }
                onMouseEnter={(e) => applyDynamicBorder(e.currentTarget)}
                onMouseLeave={(e) => removeDynamicBorder(e.currentTarget)}
                onFocus={(e) => applyDynamicBorder(e.currentTarget)}
                onBlur={(e) => removeDynamicBorder(e.currentTarget)}
              />
            </Grid>
            <Grid item xs={12} md={12} className={styles.inputContainer}>
              <Typography>Número de departamento/block/casa</Typography>
              <TextField
                fullWidth
                id="address2"
                type="text"
                autoComplete="address2"
                variant="outlined"
                size="small"
                value={props.shippingFormik.values.address2}
                onChange={props.shippingFormik.handleChange}
                error={
                  props.shippingFormik.touched.address2 &&
                  Boolean(props.shippingFormik.errors?.address2)
                }
                helperText={
                  props.shippingFormik.touched.address2 && props.shippingFormik.errors?.address2
                }
                onMouseEnter={(e) => applyDynamicBorder(e.currentTarget)}
                onMouseLeave={(e) => removeDynamicBorder(e.currentTarget)}
                onFocus={(e) => applyDynamicBorder(e.currentTarget)}
                onBlur={(e) => removeDynamicBorder(e.currentTarget)}
              />
            </Grid>
            <Grid item xs={12} className={styles.inputContainer}>
              <Typography>
                País <span className={styles.mandatoryText}>*</span>
              </Typography>
              <TextField
                fullWidth
                required
                id="country"
                type="text"
                autoComplete="country"
                variant="outlined"
                size="small"
                disabled
                value={props.shippingFormik.values.country}
                onChange={props.shippingFormik.handleChange}
                error={
                  props.shippingFormik.touched.country &&
                  Boolean(props.shippingFormik.errors?.country)
                }
                helperText={
                  props.shippingFormik.touched.country && props.shippingFormik.errors?.country
                }
                onMouseEnter={(e) => applyDynamicBorder(e.currentTarget)}
                onMouseLeave={(e) => removeDynamicBorder(e.currentTarget)}
                onFocus={(e) => applyDynamicBorder(e.currentTarget)}
                onBlur={(e) => removeDynamicBorder(e.currentTarget)}
              />
            </Grid>
            <Grid item xs={12} className={styles.inputContainer}>
              <Typography>
                Región <span className={styles.mandatoryText}>*</span>
              </Typography>
              <TextField
                fullWidth
                required
                disabled={props.payable?.payment_link_configuration?.shipping_one_region}
                select
                id="region"
                type="text"
                autoComplete="region"
                variant="outlined"
                size="small"
                value={props.shippingFormik.values.region}
                onChange={(event) =>
                  props.shippingFormik.setFieldValue('region', event.target.value)
                }
                error={
                  props.shippingFormik.touched.region &&
                  Boolean(props.shippingFormik.errors?.region)
                }
                helperText={
                  props.shippingFormik.touched.region && props.shippingFormik.errors?.region
                }
                onMouseEnter={(e) => applyDynamicBorder(e.currentTarget)}
                onMouseLeave={(e) => removeDynamicBorder(e.currentTarget)}
                onFocus={(e) => applyDynamicBorder(e.currentTarget)}
                onBlur={(e) => removeDynamicBorder(e.currentTarget)}
              >
                {props.payable?.payment_link_configuration?.shipping_one_region ? (
                  <MenuItem value={props.payable?.payment_link_configuration.shipping_regions[0]}>
                    {props.payable?.payment_link_configuration.shipping_regions[0]}
                  </MenuItem>
                ) : props.payable?.payment_link_configuration?.shipping_regions ? (
                  props.payable?.payment_link_configuration?.shipping_regions.map((region) => (
                    <MenuItem key={region} value={region}>
                      {region}
                    </MenuItem>
                  ))
                ) : (
                  REGIONS.map((region) => (
                    <MenuItem key={region[0]} value={region[1]}>
                      {region[1]}
                    </MenuItem>
                  ))
                )}
              </TextField>
            </Grid>
            <Grid item xs={12} className={styles.inputContainer}>
              <Typography>
                Comuna <span className={styles.mandatoryText}>*</span>
              </Typography>
              <TextField
                fullWidth
                required
                select
                id="commune"
                type="text"
                autoComplete="commune"
                variant="outlined"
                size="small"
                value={props.shippingFormik.values.commune}
                onChange={(event) =>
                  props.shippingFormik.setFieldValue('commune', event.target.value)
                }
                error={
                  props.shippingFormik.touched.commune &&
                  Boolean(props.shippingFormik.errors?.commune)
                }
                helperText={
                  props.shippingFormik.touched.commune && props.shippingFormik.errors?.commune
                }
                onMouseEnter={(e) => applyDynamicBorder(e.currentTarget)}
                onMouseLeave={(e) => removeDynamicBorder(e.currentTarget)}
                onFocus={(e) => applyDynamicBorder(e.currentTarget)}
                onBlur={(e) => removeDynamicBorder(e.currentTarget)}
              >
                {props.shippingFormik.values.region ? (
                  props.payable?.payment_link_configuration?.shipping_communes_by_region ? (
                    (
                      props.payable?.payment_link_configuration?.shipping_communes_by_region[
                        props.shippingFormik.values.region
                      ] || COMMUNES_BY_REGION[props.shippingFormik.values.region]
                    )
                      ?.sort()
                      .map((commune) => (
                        <MenuItem key={commune} value={commune}>
                          {commune}
                        </MenuItem>
                      ))
                  ) : (
                    COMMUNES_BY_REGION[props.shippingFormik.values.region]
                      ?.sort()
                      .map((commune) => (
                        <MenuItem key={commune} value={commune}>
                          {commune}
                        </MenuItem>
                      ))
                  )
                ) : (
                  <MenuItem>Selecciona región</MenuItem>
                )}
              </TextField>
            </Grid>
            <Grid item xs={12} className={styles.inputContainer}>
              <Typography>
                Teléfono <span className={styles.mandatoryText}>*</span>
              </Typography>
              <TextField
                fullWidth
                required
                id="phone"
                type="text"
                autoComplete="phone"
                variant="outlined"
                size="small"
                value={props.shippingFormik.values.phone}
                onChange={props.shippingFormik.handleChange}
                error={
                  props.shippingFormik.touched.phone && Boolean(props.shippingFormik.errors?.phone)
                }
                helperText={
                  props.shippingFormik.touched.phone && props.shippingFormik.errors?.phone
                }
                onMouseEnter={(e) => applyDynamicBorder(e.currentTarget)}
                onMouseLeave={(e) => removeDynamicBorder(e.currentTarget)}
                onFocus={(e) => applyDynamicBorder(e.currentTarget)}
                onBlur={(e) => removeDynamicBorder(e.currentTarget)}
              />
            </Grid>
            <Divider />
            <Grid item xs={12} className={styles.inputContainer}>
              <Typography fontStyle="italic">Código de descuento</Typography>
              <div className={styles.discountRow}>
                <TextField
                  InputProps={{
                    endAdornment: props.loadingDiscount ? (
                      <CircularProgress
                        size={20}
                        className={styles.loadIcon}
                        sx={{ color: companyColor }}
                      />
                    ) : props.validExtraDiscount === false ? (
                      <FontAwesomeIcon icon={faCircleXmark} color="red" />
                    ) : props.validExtraDiscount === undefined ? null : (
                      <FontAwesomeIcon icon={faCircleCheck} color="green" />
                    ),
                  }}
                  fullWidth
                  id="discount"
                  type="text"
                  variant="outlined"
                  size="small"
                  value={props.codeDiscountState?.state}
                  onChange={handleDiscountCodeChange}
                  onMouseEnter={(e) => applyDynamicBorder(e.currentTarget)}
                  onMouseLeave={(e) => removeDynamicBorder(e.currentTarget)}
                  onFocus={(e) => applyDynamicBorder(e.currentTarget)}
                  onBlur={(e) => {
                    removeDynamicBorder(e.currentTarget);
                  }}
                />{' '}
                {props.extraDiscount?.value && (
                  <Typography>({props.extraDiscount?.value}% extra)</Typography>
                )}
              </div>
            </Grid>
            <Divider />
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default FormSelector;
